export default {
  facturas: [],
  estatus: [],
  subiendo: false,
  cargando: false,
  validando: false,
  validandoComprobante: false,
  guardando: false,
  pendientes:[]
}
