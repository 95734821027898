import api from "../../../api"

export const setFacturasInit = ({commit}) => {
  commit("setFacturas", [])
}

export const getFacturas = async ({commit}, status) => {
  commit("setCargando", true)
  const {data} = await api.post("facturas", status)
  commit("setCargando", false)
  if (data.ok) {
    commit("setFacturas", data.facturas)
    commit("setPendientes", data.pendientes)
  } else {
    commit("setFacturas", [])
    commit("setPendientes", [])
  }
}

export const getEstatus = async({commit}) => {
  const {data} = await api.post("facturas-estatus")
  if (data.ok) {
    commit("setEstatus", data.estatus)
  } else {
    commit("setEstatus", [])
  }
}

export const setValidarXML = async ({commit}, datos) => {
  commit("setValidando", true)
  const {data} = await api.post("facturas-validar", datos)
  commit("setValidando", false)
  return data
}

export const setValidarXMLComprobante = async ({commit}, datos) => {
  commit("setValidandoComprobante", true)
  const {data} = await api.post("facturas-validar-comprobante", datos)
  commit("setValidandoComprobante", false)
  return data
}


export const setFacturar = async ({commit}, datos) => {
  commit("setGuardando", true)
  const {data} = await api.post("facturas-guardar", datos)
  commit("setGuardando", false)
  return data
}

export const setComprobante = async ({commit}, datos) => {
  const {data} = await api.post("comprobante-guardar", datos)
  return data
}



export const setSubirDocumentoFactura = async ({commit}, datos) => {
  commit("setSubiendo", true)
  const {data} = await api.post("facturas-subir-documento", datos)
  commit("setSubiendo", false)

  return data
}
