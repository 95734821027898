export const setFacturas = (state, facturas) => {
  state.facturas = facturas
}
export const setEstatus = (state, estatus) => {
  state.estatus = estatus
}

export const setSubiendo = (state, subiendo) => {
  state.subiendo = subiendo
}

export const setCargando = (state, cargando) => {
  state.cargando = cargando
}

export const setValidando = (state, validando) => {
  state.validando = validando
}

export const setValidandoComprobante = (state, validandoComprobante) => {
  state.validandoComprobante = validandoComprobante
}

export const setGuardando = (state, guardando) => {
  state.guardando = guardando
}

export const setPendientes = (state, pendientes) => {
  state.pendientes = pendientes
}